import classNames from "classnames";
import React, {
    ReactElement,
    forwardRef,
    useCallback,
    useContext,
} from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import EditWrapper from "components/EditWrapper";
import { A11y } from "style/components/A11Y";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import getButtonTheme from "utils/getButtonTheme";
import { addGtmClickTypeAttributes } from "utils/gtm";

import {
    StyledLink,
    IconArrow,
    StyledIcon,
    StyledButton,
} from "./Button.styled";
import ButtonProps from "./ButtonProps";

const Button = forwardRef(function Button(
    {
        theme,
        actionTheme,
        text,
        linkText,
        icon,
        url,
        size = "medium",
        variant = "outline",
        disabled = false,
        iconOnly = false,
        isLink,
        isExpand,
        target,
        primaryButton,
        children,
        a11y = false,
        className,
        style,
        type = "button",
        name,
        editPropertyName,
        onClick,
        onKeyDown,
        gtmClickType,
        ...props
    }: ButtonProps,
    ref:
        | ((instance: HTMLButtonElement | null) => void)
        | React.RefObject<HTMLButtonElement>
        | null
        | undefined,
): ReactElement {
    const isDisabled = disabled || false;

    text = text || linkText;
    if (children && typeof children === "string" && !text) text = children;
    const arrow =
        isLink || className === "embedded" ? (
            <IconArrow
                icon={
                    size === "large" ? "longArrowRight35" : "longArrowRight28"
                }
                aria-hidden="true"
            />
        ) : (
            <></>
        );

    const expandIcon = isExpand ? (
        <IconArrow icon={icon} aria-hidden="true" />
    ) : (
        <></>
    );
    const InnerContent = (
        <>
            {icon && !isLink && !isExpand && (
                <StyledIcon
                    icon={icon}
                    key={icon}
                    aria-hidden="true"
                    $iconOnly={iconOnly}
                />
            )}
            {a11y && <A11y>{text || children}</A11y>}
            {!a11y &&
                (text ? (
                    isLink ? (
                        appendComponentWithNoBr(text, arrow)
                    ) : isExpand ? (
                        appendComponentWithNoBr(text, expandIcon)
                    ) : (
                        <span>{text}</span>
                    )
                ) : (
                    <span>{children}</span>
                ))}
        </>
    );
    const classes = classNames(className, { disabled: disabled });

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) onClick(event);
        },
        [onClick],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (onKeyDown) onKeyDown(event);
        },
        [onKeyDown],
    );

    // Button themings
    // Fetch the theme context and use that if no theme is added
    const themeContext = useContext(ThemeContext);
    theme = theme || (themeContext && themeContext.theme);
    actionTheme = actionTheme || (themeContext && themeContext.actionTheme);
    if (iconOnly) variant = "outline";

    const buttonTheme = getButtonTheme(
        theme || "lightgray",
        actionTheme || "blue",
        primaryButton ? "primary" : (variant as string),
    );

    return (
        <ThemeProvider
            theme={{
                size: size,
                a11y: a11y,
                buttonTheme: buttonTheme,
                actionTheme: actionTheme,
            }}
        >
            <EditWrapper editPropertyName={editPropertyName}>
                {isDisabled || !url ? (
                    <StyledButton
                        className={classes}
                        aria-disabled={isDisabled}
                        tabIndex={isDisabled ? -1 : 0}
                        disabled={isDisabled}
                        type={type}
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        style={style}
                        name={name}
                        ref={ref}
                        $variant={primaryButton ? "primary" : variant}
                        $iconOnly={iconOnly}
                        {...addGtmClickTypeAttributes(gtmClickType)}
                        {...props}
                    >
                        {InnerContent}
                    </StyledButton>
                ) : (
                    <StyledLink
                        className={classes}
                        to={url}
                        exact
                        target={target}
                        style={style}
                        gtmClickType={gtmClickType}
                        $variant={primaryButton ? "primary" : variant}
                        $iconOnly={iconOnly}
                    >
                        {InnerContent}
                    </StyledLink>
                )}
            </EditWrapper>
        </ThemeProvider>
    );
});

export default React.memo(Button);
